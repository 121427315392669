exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-article-details-tsx": () => import("./../../../src/pages/article-details.tsx" /* webpackChunkName: "component---src-pages-article-details-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-homepage-about-us-tsx": () => import("./../../../src/pages/homepage/aboutUs.tsx" /* webpackChunkName: "component---src-pages-homepage-about-us-tsx" */),
  "component---src-pages-homepage-contact-us-tsx": () => import("./../../../src/pages/homepage/contactUs.tsx" /* webpackChunkName: "component---src-pages-homepage-contact-us-tsx" */),
  "component---src-pages-homepage-game-provider-tsx": () => import("./../../../src/pages/homepage/gameProvider.tsx" /* webpackChunkName: "component---src-pages-homepage-game-provider-tsx" */),
  "component---src-pages-homepage-gaming-solutions-tsx": () => import("./../../../src/pages/homepage/gamingSolutions.tsx" /* webpackChunkName: "component---src-pages-homepage-gaming-solutions-tsx" */),
  "component---src-pages-homepage-landing-carousel-tsx": () => import("./../../../src/pages/homepage/landingCarousel.tsx" /* webpackChunkName: "component---src-pages-homepage-landing-carousel-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-services-tsx": () => import("./../../../src/pages/products-services.tsx" /* webpackChunkName: "component---src-pages-products-services-tsx" */)
}

